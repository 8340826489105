import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import t from '../../lib/translate/index';
import remoteAssetUrl from '../../lib/remote-asset-url';

import CmsImageComponent from '../../content-elements/image';
import StructureText from '../../content-elements/text';
import Section from '../../component-elements/content-section';

import * as CatalogueStyle from './component.module.scss';

const CmsCatalogList = ({ data, orderLink }) => (
  <Section noGrid background="Weiß">
    {data.nodes.map(({
      id,
      name,
      description,
      file,
      orderable,
      image,
    }) => (
      <div className={CatalogueStyle.catalog} key={id}>
        <div className={CatalogueStyle.catalogHeader}>
          <div className={CatalogueStyle.catalogTitle}>
            <h2>{name}</h2>
          </div>
          <div className={CatalogueStyle.catalogButtons}>
            { orderable && (
              <div className={CatalogueStyle.catalogDownload}>
                <Link
                  to={orderLink}
                  state={{ catalog: name }}
                >
                  {t`catalog_item_order_button_label`}
                </Link>
              </div>
            )}
            <div className={CatalogueStyle.catalogDownload}>
              <a target="_blank" rel="noopener noreferrer" href={remoteAssetUrl(file.url)}>
                {t`catalog_item_download_button_label`}
              </a>
            </div>
          </div>
        </div>
        <div className={CatalogueStyle.catalogBody}>
          <div className={`${CatalogueStyle.catalogImageWrapper} col-sm-16 col-md-6 col-lg-3`}>
            <CmsImageComponent image={image} />
          </div>
          <div className={`${CatalogueStyle.catalogDescription} col-sm-16 col-md-10 col-lg-13`}>
            <StructureText content={description} />
          </div>
        </div>
      </div>
    ))}
  </Section>
);

CmsCatalogList.defaultProps = {
  orderLink: '',
};

CmsCatalogList.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  orderLink: PropTypes.string,
};

export default CmsCatalogList;
