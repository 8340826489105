// extracted by mini-css-extract-plugin
export var breadcrumb = "component-module--breadcrumb--e16db";
export var containerFluid = "component-module--container-fluid--1cc6c";
export var contentCol = "component-module--content-col--2cfaa";
export var contentColCentered = "component-module--content-col-centered--17c25";
export var contentColLast = "component-module--content-col-last--bd3d1";
export var contentHeader = "component-module--content-header--6856c";
export var contentHeaderCms = "component-module--content-header-cms--f1fff";
export var contentHeaderDate = "component-module--content-header-date--16794";
export var contentHeaderText = "component-module--content-header-text--16b7c";
export var contentHighlightCol = "component-module--content-highlight-col--c2457";
export var contentLexikon = "component-module--content-lexikon--6f19a";
export var contentSection = "component-module--content-section--f5c1f";
export var contentSectionBackground = "component-module--content-section-background--a051d";
export var contentSectionBackgroundImage = "component-module--content-section-background-image--8d820";
export var contentSectionDark = "component-module--content-section-dark--32ce6";
export var contentSectionDarkRing = "component-module--content-section-dark-ring--43bc2";
export var contentSectionDesktopSwitch = "component-module--content-section-desktop-switch--f176b";
export var contentSectionFullwidth = "component-module--content-section-fullwidth--0be7a";
export var contentSectionGold = "component-module--content-section-gold--6758d";
export var contentSectionGray = "component-module--content-section-gray--a3d5c";
export var contentSectionGrayRing = "component-module--content-section-gray-ring--d16ef";
export var contentSectionMobileSwitch = "component-module--content-section-mobile-switch--e0432";
export var contentSectionOverlap = "component-module--content-section-overlap--51c28";
export var contentSectionRing = "component-module--content-section-ring--9438e";
export var contentSectionTabletSwitch = "component-module--content-section-tablet-switch--4cc82";
export var contentSectionWhite = "component-module--content-section-white--87037";
export var figure = "component-module--figure--c3204";
export var imageContainer = "component-module--image-container--0c187";
export var productGridArticlesCrop = "component-module--product-grid-articles-crop--ffc4d";
export var row = "component-module--row--5dfa2";
export var slider = "component-module--slider--492aa";
export var sliderCrop = "component-module--slider-crop--45bb9";
export var swiperContainer = "component-module--swiper-container--80845";