import React from 'react';
import PropTypes from 'prop-types';
import ContentHeaderCms from '../../../../content-elements/contentHeaderCms';

const HeadlineHeader = ({ headline, children }) => (
  <ContentHeaderCms>
    <h1>{headline}</h1>
    {children}
  </ContentHeaderCms>
);

HeadlineHeader.defaultProps = {
  children: undefined,
};

HeadlineHeader.propTypes = {
  headline: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default HeadlineHeader;
