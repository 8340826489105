import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import t from '../lib/translate';

import Layout from '../layouts/default';
import Helmet from '../components/helmet';
import Header from '../component-elements/header';
import ContentHeaderCms from '../content-elements/contentHeaderCms';
import CmsBrandContactComponent from '../content-elements/brandContact';
import CmsCatalogList from '../components/single-catalog-list';
import Breadcrumb from '../component-elements/breadcrumb';

const components = {
  DatoCmsTextSimple: ContentHeaderCms,
  DatoCmsBrandContact: CmsBrandContactComponent,
};

const CatalogTemplate = ({ data, pageContext }) => {
  const { datoCmsPage, allDatoCmsCatalog, datoCmsConfig } = data;
  const helmetProps = datoCmsPage.seoMetaTags;
  const headerElement = datoCmsPage.header[0];
  const { metaRobots, metaCanonical } = datoCmsPage;
  return (
    <Layout textcolorHeader="black" textcolorHeaderMobile="black">
      <Breadcrumb
        items={[
          { id: datoCmsPage.name, name: datoCmsPage.name },
        ]}
      />

      {headerElement && (
        <Header data={headerElement} />
      )}

      {datoCmsPage.content.map((singleComponent) => {
        if ((typeof singleComponent === 'object') && Object.keys(singleComponent).length > 0) {
          const Component = components[singleComponent.__typename];
          return (
            <Component key={singleComponent.__typename} {...singleComponent} />
          );
        }
        return (
          <div><h4>This content element can not be rendered.</h4></div>
        );
      })}

      <CmsCatalogList
        data={allDatoCmsCatalog}
        orderLink={datoCmsConfig.pageCatalogOrder.fullUrlPath}
      />

      <Helmet
        tags={helmetProps}
        metaRobots={metaRobots}
        metaCanonical={metaCanonical}
        title={datoCmsPage.seo?.title}
        titleFallback={t('cms_page_title_fallback', datoCmsPage.name)}
        url={pageContext.url}
      />
    </Layout>
  );
};

CatalogTemplate.defaultProps = {
  seoMetaTags: null,
  datoCmsPage: null,
};

CatalogTemplate.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  datoCmsPage: PropTypes.objectOf(PropTypes.any),
  seoMetaTags: PropTypes.objectOf(PropTypes.array),
    pageContext: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default CatalogTemplate;

export const query = graphql`
query catalogQuery($url: String $lang: String) {
    datoCmsPage(url: {eq: $url}, locale: {eq: $lang}) {
        ...SeoMetaTags
        ...header
        ...content
        metaCanonical
        metaRobots
    }
    datoCmsConfig(locale: {eq: $lang}) {
        pageCatalogOrder {
            fullUrlPath
        }
    }
    allDatoCmsCatalog(
        filter: {locale: {eq: $lang}}
        sort: {fields: date, order: DESC}
    ) {
        nodes {
            date(formatString: "DD.MM.YYYY")
            name
            id
            file {
                url
                filename
            }
            orderable
            image {
                alt
                title
                gatsbyImageData(
                    forceBlurhash: true,
                    imgixParams: {
                        w: "258"
                        auto: "compress"
                        q: 70
                        dpr: 1.5
                    }
                )
            }
            description {
                value
            }
        }
    }
}
`;
