import React from 'react';
import PropTypes from 'prop-types';
import isNotEmpty from '../../lib/is-not-empty-structured-text';
import CmsTextComponent from '../text';
import Contact from './contact/index';
import Section from '../../component-elements/content-section/index';

const CmsBrandContactComponent = (props) => {
  const {
    title,
    headline,
    text,
    contacts,
    contactSpecialization,
    background,
  } = props;

  const allContacts = (contacts || [])
    .concat(contactSpecialization ? contactSpecialization.contacts : []);

  const contactIds = [];
  const contact = allContacts
    .filter((c) => {
      if (contactIds.includes(c.id)) return false;
      contactIds.push(c.id);
      return true;
    })
    .slice(0, 2);
  return (
    <Section background={background}>
      <div className="content-col content-col-first col-xl-5 content-highlight-col">
        {
          (isNotEmpty(headline) || title) && (
          <header className="section-header">
            { isNotEmpty(headline) && <CmsTextComponent content={headline} /> }
            { title && <span className="section-subtitle">{title}</span>}
          </header>
          )
        }
        <CmsTextComponent content={text} />
      </div>
      {
        contact[0] && (
          <div className="content-col content-col-last col-xl-5 offset-xl-1 col-sm-8">
            <Contact
              specialization={contactSpecialization?.designation}
              data={contact[0]}
            />
          </div>
        )
      }
      {
        contact[1] && (
          <div className="content-col content-col-last col-xl-5 col-sm-8">
            <Contact
              specialization={contactSpecialization?.designation}
              data={contact[1]}
            />
          </div>
        )
      }
    </Section>
  );
};
CmsBrandContactComponent.defaultProps = {
  contactSpecialization: {},
};
CmsBrandContactComponent.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.objectOf(PropTypes.any).isRequired,
  headline: PropTypes.objectOf(PropTypes.any).isRequired,
  contacts: PropTypes.arrayOf(PropTypes.any).isRequired,
  background: PropTypes.string.isRequired,
  contactSpecialization: PropTypes.objectOf(PropTypes.any),
};
export default CmsBrandContactComponent;
