import gatsbyImageDataUrlsWith from './gatsby-image-data-urls-with';

// eslint-disable-next-line consistent-return
const remoteAssetUrl = (src, options = {}) => {
  if (process.env.GATSBY_FEATURE_ASSET_REMOTE_ENABLED !== '1') {
    return src;
  }

  if (!src) {
    return '';
  }

  if (typeof src === 'string') {
    // eslint-disable-next-line no-use-before-define
    return str(src, options);
  }

  if (typeof src === 'object') {
    // eslint-disable-next-line no-use-before-define
    return gatsbyImageDataUrlsWith(src, (url) => str(url, options));
  }
};

const str = (src, { filename: altFilename }) => {
  if (!src || src.length === 0) {
    return '';
  }

  if (!src.match(/^https:\/\/www\.datocms-assets\.com\//)) {
    return src;
  }

  const path = src.replace(/https:\/\/www\.datocms-assets\.com\//, '');

  if (process.env.GATSBY_FEATURE_ASSET_REMOTE_URLTRANSFORM !== '1') {
    return `${process.env.GATSBY_FEATURE_ASSET_REMOTE_BASEURL}/${path}`;
  }

  // GATSBY_FEATURE_ASSET_REMOTE_URLTRANSFORM enabled

  const pathMatch = path.match(/^([0-9]{1,99})\/([0-9]{1,99})-/);
  if (!pathMatch || pathMatch.length !== 3) {
    return `${process.env.GATSBY_FEATURE_ASSET_REMOTE_BASEURL}/${path}`;
  }

  let newPath = path.replace(pathMatch[0], '');
  if (path.includes('?')) {
    newPath += '&';
  } else {
    newPath += '?';
  }

  newPath += `mp_dir=${pathMatch[1]}&mp_id=${pathMatch[2]}`;

  const paramsMatch = newPath.match(/\?(.*)/);
  if (!paramsMatch || paramsMatch.length !== 2) {
    return `${process.env.GATSBY_FEATURE_ASSET_REMOTE_BASEURL}/${newPath}`;
  }

  const origFilename = newPath.replace(paramsMatch[0], '');

  if (!altFilename) {
    return `${
      process.env.GATSBY_FEATURE_ASSET_REMOTE_BASEURL
    }/${origFilename}?mp_enc=${btoa(paramsMatch[1])}`;
  }

  // ignore alt filename when it is an url
  if (altFilename.includes('http://') || altFilename.includes('https://')) {
    return `${
      process.env.GATSBY_FEATURE_ASSET_REMOTE_BASEURL
    }/${origFilename}?mp_enc=${btoa(paramsMatch[1])}`;
  }

  return `${
    process.env.GATSBY_FEATURE_ASSET_REMOTE_BASEURL
  }/${encodeURIComponent(altFilename.trim())}?mp_enc=${btoa(
    `${paramsMatch[1]}&mp_filename=${origFilename}`,
  )}`;
};

export default remoteAssetUrl;
