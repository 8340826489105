import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'gatsby';
import { StructuredText } from 'react-datocms';
import { isLink, renderRule } from 'datocms-structured-text-utils';

/**
 * custom transformer for meta-data that includes "class" and "data-" attributes
 * (the default transformer strips those attributes but we'd like to have them in the meta-data)
 *
 * @param {Array} meta
 *
 * @return {Object}
 */
const metaTransformer = ({ meta }) => (meta || []).reduce((carry, entry) => {
  if (['target', 'title', 'rel', 'class'].includes(entry.id) || entry.id.startsWith('data-')) {
    // eslint-disable-next-line no-param-reassign
    carry[entry.id] = entry.value;
  }
  return carry;
}, {});

/**
 * removes "class" attribute from object and returns it's value
 *
 * @param {Object|null} obj
 *
 * @return {string}
 */
const cssClassFromObj = (obj) => {
  if (!obj) {
    return '';
  }

  let className = '';
  if (obj.class) {
    className = ` ${obj.class}`;
    // eslint-disable-next-line no-param-reassign
    delete obj.class;
  }

  return className;
};

/**
 * render a record
 *
 * @param {Object} basePaths - may contain a url base path by record typename
 * @param {Object} params
 *
 * @returns {JSX.Element}
 */
const renderRecord = (basePaths, params) => {
  const { record, children, transformedMeta } = params;

  const urlPath = [];
  // const externalPath = [];

  if (basePaths && basePaths[record.__typename]) {
    // add the basepath to the url path
    urlPath.push(`/${basePaths[record.__typename]}`);
  }

  // match records by typename
  // set "recordName" and add urlPath segments accordingly
  let recordName;
  switch (record.__typename) {
    case 'DatoCmsPage':
      if (!record?.fullUrlPath) {
        // eslint-disable-next-line no-console
        console.warn(`cannot link to Page, missing fullUrlPath: ${JSON.stringify(record)}`);
        return null;
      }
      recordName = record.name;
      urlPath.push(record.fullUrlPath);
      break;
    case 'DatoCmsNews':
      if (!record?.fullUrlPath) {
        // eslint-disable-next-line no-console
        console.warn(`cannot link to News, missing fullUrlPath: ${JSON.stringify(record)}`);
        return null;
      }
      recordName = record.title;
      urlPath.push(record.fullUrlPath);
      break;
    case 'DatoCmsBrand':
      if (!record?.fullUrlPath) {
        // eslint-disable-next-line no-console
        console.warn(`cannot link to Brand, missing fullUrlPath: ${JSON.stringify(record)}`);
        return null;
      }
      if (record.isExternal) {
        return <a className="btn btn-primary" href={record.fullUrlPath} target="_blank" rel="noreferrer">{record.name}</a>;
      }
      recordName = record.name;
      urlPath.push(record.fullUrlPath);
      break;
    case 'DatoCmsShopCategory':
      if (!record?.magentoCategory?.fullUrlPath) {
        // eslint-disable-next-line no-console
        console.warn(`cannot link to ShopCategory, missing MagentoCategory.fullUrlPath: ${JSON.stringify(record)}`);
        return null;
      }
      recordName = record.magentoCategory.name;
      urlPath.push(record.magentoCategory.fullUrlPath);
      break;
    case 'DatoCmsProductCollection':
      if (!record?.fullUrlPath) {
        // eslint-disable-next-line no-console
        console.warn(`cannot link to ProductCollection, missing fullUrlPath: ${JSON.stringify(record)}`);
        return null;
      }
      recordName = record.name;
      urlPath.push(record.fullUrlPath);
      break;
    default:
      throw new Error(`Button: record type is not supported: ${record.__typename}`);
  }

  // include additional attributes from transformed-meta
  const attributes = transformedMeta;
  const addClassName = cssClassFromObj(attributes);

  // render a link, add transformedMeta to it
  // either use children as link text or the record-name

  if (!urlPath || urlPath.length === 0) {
    throw new Error(`Button url path is empty: ${record.__typename}`);
  }

  return (
    <Link
      to={urlPath.join('')}
      className={`btn btn-primary${addClassName}`}
      {...attributes}
    >
      {children && children.length > 0 && <>{children}</>}
      {(!children || children.length === 0) && <>{recordName}</>}
    </Link>
  );
};

/**
 * component
 *
 * @param {Object} props
 *
 * @return {JSX.Element|null}
 * @constructor
 */
const StructuredTextButton = (props) => {
  const { content, basePaths, ...options } = props;
  if (!content) {
    // return early if no content is given
    return null;
  }

  // clean up content links: use "originalId" for "id"
  content.links = (content.links || []).map((el) => {
    if (el.originalId && el.id !== el.originalId) {
      // eslint-disable-next-line no-param-reassign
      el.id = el.originalId;
    }
    return el;
  });

  return (
    <StructuredText
      data={content}
      renderLinkToRecord={(p) => renderRecord(basePaths, p)}
      renderInlineRecord={(p) => renderRecord(basePaths, p)}
      metaTransformer={metaTransformer}
      customRules={[
        // simple links need to be rendered differently
        renderRule(
          isLink,
          ({ node, children, key }) => {
            // include additional attributes from the node
            const attributes = metaTransformer({ meta: node.meta });
            const addClassName = cssClassFromObj(attributes);

            // add rel="norefferer" for external urls
            if (node.url.startsWith('http://') || node.url.startsWith('https://')) {
              const rels = [attributes.rel || null];
              rels.push('norefferer');
              attributes.rel = rels.filter((r) => r != null).join(' ');
            }

            // handle "index" url
            if (node.url.replace(/\//g, '') === 'index') {
              // eslint-disable-next-line no-param-reassign
              node.url = '';
            }

            // render a simple link, use the "children" as text
            return (
              <a key={key} href={`${node.url}`} {...attributes} className={`btn btn-primary${addClassName}`}>{children}</a>
            );
          },
        ),
      ]}
      {...options}
    />
  );
};

StructuredTextButton.defaultProps = {
  basePaths: null,
};

StructuredTextButton.propTypes = {
  content: PropTypes.objectOf(PropTypes.any).isRequired,
  basePaths: PropTypes.objectOf(PropTypes.string),
};

export default StructuredTextButton;
