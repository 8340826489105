import * as React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import tel from '../../../lib/tel';

const Contact = ({ specialization, data }) => {
  const contact = data;
  if (!contact) {
    return null;
  }
  const spec = specialization || (contact.specialization.map((s) => s.designation).join(', '));
  return (
    <div className="contact">
      <div className="contact-details">
        {
          (contact.firstname || contact.lastname) && (
            <h4
              className="contact-name"
            >
              {`${contact.firstname} ${contact.lastname}`}
            </h4>
          )
        }
        <p className="contact-position">
          {`${spec}`}
        </p>
        {
          (contact.phone || contact.email) && (
            <dl className="contact-list">
              {
                contact.phone && (
                  <>
                    <dt className="icon icon-phone icon-gold">
                      <span className="sr-only">phone</span>
                    </dt>
                    <dd><a href={tel(contact.phone)}>{contact.phone}</a></dd>
                  </>
                )
              }
              {
                contact.email && (
                  <>
                    <dt className="icon icon-mail icon-gold">
                      <span className="sr-only">email</span>
                    </dt>
                    <dd><a href={`mailto:${contact.email}`} target="_blank" rel="noreferrer">{contact.email}</a></dd>
                  </>
                )
              }
            </dl>
          )
        }
      </div>
      {
        contact?.image?.gatsbyImageData && (
          <figure className="contact-figure">
            <GatsbyImage
              image={contact.image.gatsbyImageData}
              alt={contact.image.alt}
              title={contact.image.title}
              className="contact-image"
            />
          </figure>
        )
      }
    </div>
  );
};
Contact.defaultProps = {
  specialization: '',
};
Contact.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  specialization: PropTypes.string,
};
export default Contact;
