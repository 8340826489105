/* eslint-disable */
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'gatsby';

import t from '../../lib/translate';

import {StructuredDataList} from '../../components/structured-data';

import * as styles from './component.module.scss';

const Breadcrumb = ({items, className = undefined, structuredDataItems = undefined, showAtlas = false}) => {
			const [shown, setShown] = useState(false);
			const [atlasShown, setAtlasShown] = useState(false);

			if(structuredDataItems === undefined) {
				structuredDataItems = [items];
			}

			const structuredData = structuredDataItems.map((item) => (
					{
						type: 'BreadcrumbList',
						...{
							itemListElement: item.map(({url, name}, i) => ({
								'@type': 'ListItem',
								position: i + 1,
								name,
								item: url ? `${process.env.GATSBY_WEB_URL}${url}` : undefined
							}))
						}
					}
			));

			return (
					<>
						<section
								className={`breadcrumb ${styles.breadcrumb}${
										className ? ` ${className}` : ''
								}${shown ? ` ${styles.showMobileBreadcrumb}` : ''}`}
						>
							<h4 className="sr-only">{t`breadcrumb_label`}</h4>
							<ol className={`${styles.list}`}>
								<button
										type="button"
										className={`${styles.mobileBreadcrumbButton}`}
										onClick={() => setShown(!shown)}
								>
									&hellip;
								</button>
								<li className={styles.item}>
									<Link className={styles.link} to="/">
										{t`breadcrumb_home_item_label`}
									</Link>
								</li>
								{items.map(({id, url, name}) => (
										<li key={id} className={styles.item}>
											{url && (
													<Link className={styles.link} to={url}>
														{name}
													</Link>
											)}
											{!url && <span className={styles.act}>{name}</span>}
										</li>
								))}
							</ol>

							{showAtlas && <div className={`container-fluid ${styles.atlasContainer}`}>
								<button
										type="button"
										className={`${styles.showAtlasButton} btn btn-blank icon ${atlasShown ? 'icon-close' : 'icon-atlas'} icon-gold`}
										onClick={() => setAtlasShown(!atlasShown)}
										title={t`atlas_label`}
								>
									<span className="sr-only">{t`open_atlas_button`}</span>
								</button>
								<div className={`${styles.atlas} ${atlasShown ? styles.atlasShown : ''}`}>
									<h4>{t`atlas_label`}</h4>
									{structuredDataItems.map((item) => (
											<ol className={`${styles.atlasList} icon icon-gold icon-right`}>
												{item.map(({id, url, name}) => (
														<>
															{url && (
																	<li key={id} className={styles.item}>
																		<Link className={styles.link} to={url}>
																			{name}
																		</Link>
																	</li>
															)}
														</>
												))}
											</ol>
									))}
								</div>
							</div>}
						</section>

						<StructuredDataList
								listItems={structuredData}
						/>
					</>
			);
		}
;

Breadcrumb.defaultProps = {
	className: undefined
};

Breadcrumb.propTypes = {
	items: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.string.isRequired,
				name: PropTypes.string.isRequired,
				url: PropTypes.string
			})
	).isRequired,
	className: PropTypes.string
};

export default Breadcrumb;
