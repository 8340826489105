import React from 'react';
import PropTypes from 'prop-types';

import CmsTextComponent from '../text';
import Section from '../../component-elements/content-section';

import * as ContentCmsStyle from './component.module.scss';

const ContentHeaderCms = (props) => {
  const {
    headline,
    content,
    background,
    children,
  } = props;

  return (
    <>
      <section className={`${ContentCmsStyle.contentHeaderCms} content-header-cms`}>
        <div className="container-fluid">
          <div className={ContentCmsStyle.contentHeaderText}>
            {headline && <CmsTextComponent content={headline} />}
            {children}
          </div>
        </div>
      </section>

      {content && (
        <Section noGrid className={ContentCmsStyle.contentSection} background={background}>
          <CmsTextComponent content={content} />
        </Section>
      )}
    </>
  );
};

ContentHeaderCms.defaultProps = {
  background: undefined,
  children: undefined,
  headline: undefined,
  content: undefined,
};

ContentHeaderCms.propTypes = {
  background: PropTypes.string,
  headline: PropTypes.objectOf(PropTypes.any),
  content: PropTypes.objectOf(PropTypes.any),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default ContentHeaderCms;
