import React from 'react';
import PropTypes from 'prop-types';
import * as ContentCmsStyle from './component.module.scss';
import CmsImageComponent from '../../content-elements/image';

const ContentHeader = (props) => {
    const {
        headerImage,
        headerImageMobile,
        logo,
        className,
        children,
    } = props;

    // with images
    if (headerImage?.gatsbyImageData) {
        return (
            <section className={`${ContentCmsStyle.contentHeader} content-header ${className}`}>
                {headerImage?.gatsbyImageData && (
                    <CmsImageComponent image={headerImage} className={ContentCmsStyle.contentHeaderBackground} loading="eager" useHTMLImg />
                )}

                {headerImageMobile?.gatsbyImageData && (
                    <CmsImageComponent image={headerImageMobile} className={ContentCmsStyle.contentHeaderBackgroundMobile} loading="eager" useHTMLImg />
                )}

                <div className="container-fluid">
                    <div className={ContentCmsStyle.contentHeaderText}>
                        {children}
                    </div>
                </div>
            </section>
        );
    }

    // without images
    return (
        <section className={`${ContentCmsStyle.contentHeader} ${ContentCmsStyle.contentHeaderNoDesktopImage} ${ContentCmsStyle.contentHeaderNoMobileImage} ${className}`}>
            <div className={ContentCmsStyle.contentHeaderBrand}>
                {logo?.gatsbyImageData && (
                    <CmsImageComponent image={logo} loading="eager" />
                )}
            </div>
            <div className="container-fluid">
                <div className={ContentCmsStyle.contentHeaderText}>
                    {children}
                </div>
            </div>
        </section>
    );
};

ContentHeader.defaultProps = {
    headerImage: undefined,
    headerImageMobile: undefined,
    logo: undefined,
    children: null,
    className: '',
};

ContentHeader.propTypes = {
    headerImage: PropTypes.objectOf(PropTypes.any),
    headerImageMobile: PropTypes.objectOf(PropTypes.any),
    logo: PropTypes.objectOf(PropTypes.any),
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    className: PropTypes.string,
};

export default ContentHeader;
