import React from 'react';
import PropTypes from 'prop-types';
import CmsTextComponent from '../../content-elements/text';
import StructuredTextButton from '../../content-elements/textButton';
import ContentHeader from '../contentHeaderBackground';
import HeadlineHeader from './types/headline';

const Header = ({ data }) => {
  const {
    __typename,
    image,
    button,
    headline,
    imageMobile,
    text,
  } = data;

  switch (__typename) {
    case 'DatoCmsHeadlineHeader':
      return (<HeadlineHeader headline={text} />);
    case 'DatoCmsImageHeader':
      return (
        <ContentHeader headerImage={image} headerImageMobile={imageMobile}>
          <CmsTextComponent content={headline} />
          <StructuredTextButton content={button} />
        </ContentHeader>
      );
    default:
      return null;
  }
};
Header.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default Header;
